export default [
  // Google
  '/gtag/js',

  // Fetch
  'signal is aborted without reason',
  'The operation was aborted',
  'Load failed TypeError',

  // Stripe
  'frame window is not ready',
  'Window closed before response',

  // Vercel
  '/_vercel/',

  // Other
  'Importing a module script failed',

  // Manual
  'No cart found',
]
